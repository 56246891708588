import React from "react"
import { Button, Col, Icon, Tag, Tooltip, Typography } from "antd"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"
import style from "./BlogList.module.scss"

export class BlogCard extends React.Component {
  constructor(props) {
    super(props)
    const { content, slug, title } = this.props.post
    const uri = slug
      ? slug
      : title
          .replace(/['’ʻ]/g, ``)
          .split(/[^a-zA-Z0-9]/gm)
          .filter(x => x.length)
          .join(`-`)
          .toLowerCase()
    const ttr = content.childMarkdownRemark.timeToRead

    this.state = {
      uri: uri,
      path: `/blog/${uri}/`,
      ttrStr: `${ttr} min${ttr > 1 ? `s` : ``}`,
    }
  }

  lastRead = uri => {
    if (typeof window === "undefined") {
      return
    }
    const readList = localStorage.getItem("blogsRead")
    if (readList !== null && readList.includes(uri)) {
      const sec = JSON.parse(readList)[uri]
      return (
        <Tooltip
          title={`You last read this article ${moment(sec).format(
            "MMM DD, YYYY"
          )}`}
        >
          <Tag color="volcano">
            <Icon type="read" />
            {` Read`}
          </Tag>
        </Tooltip>
      )
    }
    return <></>
  }

  render() {
    const {
      content,
      featuredImage,
      id,
      publishedAt,
      tags,
      title,
    } = this.props.post

    const {
      buttonText,
      excerptRows,
      hideDate,
      hideExcerpt,
      hideImage,
      hideReadTag,
      hideTimeToRead,
      hideTitle,
      titleRows,
    } = this.props

    const { postCol, showPost } = this.props

    const { path, ttrStr, uri } = this.state

    const colProps = postCol || {
      xs: 22,
      md: 11,
      lg: 7,
      xl: 6,
      key: id,
    }

    const imgProps = {
      alt: title,
      className: style.image,
      fluid: featuredImage.fluid,
    }

    return (
      <Col
        {...colProps}
        className={`${style.blogsWrapper} ${showPost ? showPost(tags) : ``}`}
      >
        {hideImage ? null : (
          <Link to={path}>
            <Img {...imgProps} />
          </Link>
        )}
        {hideTitle ? null : (
          <Link to={path}>
            <Typography.Title
              ellipsis={{
                expandable: false,
                rows: titleRows || 2,
              }}
              level={2}
            >
              {title}
            </Typography.Title>
          </Link>
        )}
        {hideDate && hideReadTag ? null : (
          <Typography.Title level={4} className={style.postInfo}>
            {hideDate ? (
              ``
            ) : (
              <span style={{ marginRight: 8 }}>{publishedAt}</span>
            )}
            {hideReadTag ? `` : this.lastRead(uri)}
          </Typography.Title>
        )}
        {hideExcerpt ? null : (
          <Typography.Paragraph
            ellipsis={{
              expandable: false,
              rows: excerptRows || 4,
            }}
          >
            {content.childMarkdownRemark.excerpt}
          </Typography.Paragraph>
        )}
        <Link to={path}>
          <Button type="ghost">
            {`${buttonText || `Keep Reading`}${
              hideTimeToRead ? `` : ` (${ttrStr})`
            }`}
          </Button>
        </Link>
      </Col>
    )
  }
}

class BlogList extends React.Component {
  state = {
    filter: [],
    tags: [],
  }

  componentDidMount() {
    let tags = []
    this.props.nodes.forEach(node => {
      node.tags.forEach(tag => {
        tags.push(tag.trim())
      })
    })
    this.setState({
      tags: [...new Set(tags)],
    })
  }

  toggleFilter = tag => {
    let f = this.state.filter
    if (!f.includes(tag)) {
      f.push(tag)
    } else {
      f.splice(f.indexOf(tag), 1)
    }
    this.setState({
      filter: f,
    })
  }

  isChecked = tag => {
    return this.state.filter.includes(tag)
  }

  tagIcon = tag => {
    const iconProps = {
      className: style.tagIcon,
      type: this.isChecked(tag) ? `stop` : `search`,
    }
    return <Icon {...iconProps} />
  }

  showPost = tagArr => {
    if (this.state.filter.length === 0) {
      return style.show
    }
    let show = false
    tagArr.forEach(tag => {
      if (this.state.filter.includes(tag)) {
        show = true
      }
    })
    return show ? style.show : style.hide
  }

  render() {
    return (
      <>
        <Col className={style.tagCol} span={22}>
          <Typography.Title level={4}>Filter Posts</Typography.Title>
          {this.state.tags.map(tag => {
            return (
              <Tag.CheckableTag
                className={
                  this.isChecked(tag) ? style.checked : style.unchecked
                }
                key={tag}
                checked={() => this.isChecked(tag)}
                onChange={() => this.toggleFilter(tag)}
              >
                {this.tagIcon(tag)}
                {tag}
              </Tag.CheckableTag>
            )
          })}
        </Col>
        {this.props.nodes.map((node, index) => (
          <BlogCard key={index} post={node} showPost={this.showPost} />
        ))}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allContentfulBlogPost(
          filter: { hotel: { name: { eq: "Lakeside Lodge Clemson" } } }
          sort: { fields: publishedAt, order: DESC }
        ) {
          nodes {
            slug
            title
            tags
            publishedAt(formatString: "MMM DD, YYYY")
            # updatedAt(formatString: "MMM DD, YYYY")
            id
            content {
              childMarkdownRemark {
                timeToRead
                excerpt
              }
            }
            featuredImage {
              fluid(maxWidth: 350) {
                ...fluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    `}
    render={data => <BlogList nodes={data.allContentfulBlogPost.nodes} />}
  />
)
