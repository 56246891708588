import React from "react"
import { Icon, List } from "antd"
import style from "./Attractions.module.scss"

function mapsLink(address) {
  return `https://google.com/maps/place/${address.replace(/ /g, "+")}`
}

export default ({ attractions }) => (
  <List
    dataSource={attractions}
    itemLayout="vertical"
    renderItem={({ address, description, name, phoneNumber, website }) => (
      <List.Item
        actions={[
          phoneNumber ? (
            <span className={style.action}>
              <Icon type="phone" /> <a href={`tel:${phoneNumber}`}>Call</a>
            </span>
          ) : (
            <></>
          ),
          address ? (
            <span className={style.action}>
              <Icon type="environment" />{" "}
              <a
                href={`${mapsLink(address)}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                View on Map
              </a>
            </span>
          ) : (
            <></>
          ),
          website ? (
            <span className={style.action}>
              <Icon type="global" />{" "}
              <a href={`${website}`} rel="noopener noreferrer" target="_blank">
                Website
              </a>
            </span>
          ) : (
            <></>
          ),
        ]}
        className={style.listItem}
        key={name}
      >
        <List.Item.Meta
          className={style.listItemMeta}
          description={
            <>
              {phoneNumber ? (
                <>
                  <span>{phoneNumber.substring(2)}</span>
                  <br />
                </>
              ) : null}
              {address ? <span>{address}</span> : null}
            </>
          }
          title={name}
        />
        <div
          className={style.description}
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        ></div>
      </List.Item>
    )}
    size="large"
  />
)
