import React from "react"
import Img from "gatsby-image"
import style from "./SharpHero.module.scss"

const SharpHero = ({
  caption,
  contentfulAsset,
  customNode,
  height,
  imageStyle,
  pictureStyle = {},
}) => {
  const { description, localFile } = contentfulAsset

  const wrapperProps = {
    className: style.wrapper,
    style: {
      height: height || `54vh`,
    },
  }

  const imgProps = {
    alt: description,
    fluid: localFile.childImageSharp.fluid,
    style: {
      height: `100%`,
      width: `100%`,
      ...imageStyle,
    },
    imgStyle: { ...pictureStyle },
  }

  const captionProps = {
    className: style.captionWrapper,
  }

  return (
    <div {...wrapperProps}>
      <Img {...imgProps} />
      {customNode ? <>{customNode}</> : <div {...captionProps}>{caption}</div>}
    </div>
  )
}

export default SharpHero
