import React from "react"
import { Col, Row } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import Attractions from "../components/Attractions/Attractions"
import BlogReel from "../components/BlogReel/BlogReel"

const rowProps = {
  align: "middle",
  gutter: 32,
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const fullColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

export default ({ data }) => {
  const { hero } = data
  const meta = {
    seo: {
      title: "Clemson Area Attractions | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
    },
    uri: "/attractions/",
  }

  return (
    <Shell>
      <Meta {...meta} />
      <Hero
        caption="Local Attractions"
        contentfulAsset={data.hero}
        height={`45vh`}
        imageStyle={{ filter: `brightness(0.75)` }}
        pictureStyle={{ objectPosition: `center` }}
      />
      <BookingBar showCTAButtons={false} />
      <Row {...rowProps}>
        <Col {...fullColProps}>
          <Attractions attractions={data.attractions.nodes} />
        </Col>
      </Row>
      <BlogReel numPosts={3} />
    </Shell>
  )
}

export const query = graphql`
  query {
    attractions: allContentfulAttraction(
      filter: {
        business: { elemMatch: { name: { eq: "Lakeside Lodge Clemson" } } }
      }
    ) {
      nodes {
        address
        description {
          childMarkdownRemark {
            html
          }
        }
        name
        phoneNumber
        website
      }
    }
    hero: contentfulAsset(title: { eq: "LLC - Clemson Water Tower" }) {
      localFile {
        childImageSharp {
          fluid(
            jpegProgressive: true
            jpegQuality: 80
            maxWidth: 1500
            webpQuality: 80
          ) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  }
`
